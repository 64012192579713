import { format, parseISO } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

import { dateIsPast } from './dates.helpers';
import { setDateRange } from './filter.helpers';
import { addToFilterObj } from './portfolio.helpers';

export const formatDate = (date) => {
  if (date === undefined || date === null || date === '') {
    return '-';
  }
  const parsedDate = parseISO(date);
  const zoned = toZonedTime(parsedDate, 'UTC');
  const monthAbbreviation = format(zoned, 'MMM');
  const formattedDate = format(zoned, 'd');
  const year = format(zoned, 'y');
  return `${monthAbbreviation} ${formattedDate}, ${year}`;
};

export const formatDateAsYMD = (date) => {
  return date.toISOString().split('T')[0];
};

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0,
});

export const formatMoney = (value) => {
  if (value === undefined) {
    return '-';
  }
  return currencyFormatter.format(value);
};

export const formatSearchQuery = (searchQuery) => {
  if (searchQuery === null) {
    return null;
  }
  if (searchQuery.type === 'title') {
    return {
      artwork: {
        title: {
          $containsi: searchQuery.label,
        },
      },
    };
  } else if (searchQuery.type === 'artist') {
    const name = searchQuery.label;
    return {
      artwork: {
        artist: {
          full_name: {
            $containsi: name,
          },
        },
      },
    };
  } else if (searchQuery.type === 'collection') {
    return {
      tags: {
        $and: [
          {
            value: {
              $containsi: searchQuery.label,
            },
          },
          {
            key: {
              $eqi: 'collection',
            },
          },
        ],
      },
    };
  }
};

export const formatAppraisalSearchQuery = (searchQuery) => {
  return {
    $or: [
      {
        item: {
          artwork: {
            title: {
              $containsi: searchQuery,
            },
          },
        },
      },
      {
        item: {
          artwork: {
            artist: {
              full_name: {
                $containsi: searchQuery,
              },
            },
          },
        },
      },
    ],
    status: {
      $eqi: ['DELIVERED', 'CANCELED'],
    },
  };
};

export const formatItemApiResponse = (data) => {
  if (data === undefined) {
    return;
  }
  const formatItem = (item) => {
    const id = item.id;
    const attributes = item.attributes;
    const artwork_attributes = attributes.artwork?.data?.attributes;
    const artwork_id = attributes.artwork?.data?.id;
    const appraisals = attributes.appraisals?.data;
    const tags = attributes.tags?.data;
    let notes = attributes.notes?.data;
    let noteId,
      mostRecentAppraisalId = null;
    if (notes === null || notes === undefined) {
      notes = '';
    } else {
      if (notes.length === 0) {
        notes = '';
      } else {
        noteId = notes[0].id;
        notes = notes[0].attributes?.text;
      }
    }
    const subscription = attributes.subscription?.data;
    let title,
      size_width,
      medium_category,
      size_height,
      size_depth,
      size_units,
      collection,
      artist,
      provenance,
      date_acquired,
      description,
      edition,
      exhibitions,
      publications,
      location,
      medium,
      year,
      condition,
      fmv,
      rrv,
      artwork_status,
      organization_name,
      lastReport = '';
    let subscriptionId,
      organization_id = null;
    let tagsList = [];
    let images = [];
    if (attributes.images?.data?.length > 0) {
      attributes.images.data.forEach((image) => images.push(image));
    }
    let attachmentsArray = [];
    if (artwork_attributes) {
      const artist_attributes = artwork_attributes.artist?.data?.attributes;
      const imageArray = artwork_attributes.images?.data;
      title = artwork_attributes.title;
      size_width =
        artwork_attributes.size_width === null ||
        artwork_attributes.size_width === undefined
          ? ''
          : artwork_attributes.size_width;
      size_height =
        artwork_attributes.size_height === null ||
        artwork_attributes.size_height === undefined
          ? ''
          : artwork_attributes.size_height;
      size_depth =
        artwork_attributes.size_depth === null ||
        artwork_attributes.size_depth === undefined
          ? ''
          : artwork_attributes.size_depth;
      size_units =
        artwork_attributes.size_units === null ||
        artwork_attributes.size_units === undefined
          ? ''
          : artwork_attributes.size_units;
      year =
        artwork_attributes.year === null ||
        artwork_attributes.year === undefined
          ? ''
          : artwork_attributes.year;
      provenance =
        artwork_attributes.provenance == null
          ? ''
          : artwork_attributes.provenance;
      medium_category =
        artwork_attributes.medium_category == null
          ? ''
          : artwork_attributes.medium_category;
      medium =
        artwork_attributes.medium == null ? '' : artwork_attributes.medium;
      edition =
        artwork_attributes.edition == null ? '' : artwork_attributes.edition;
      artwork_status =
        artwork_attributes.status == null ? '' : artwork_attributes.status;
      if (artist_attributes) {
        artist = artist_attributes.full_name;
      }
      if (images.length === 0 && imageArray?.length > 0) {
        // default to artwork images if item images are not present
        images = imageArray;
      }
    }

    if (tags) {
      collection = tags.find(
        (tag) => tag.attributes.key.toLowerCase() === 'collection',
      )?.attributes?.value;
      for (let tag of tags) {
        const tagValue = tag.attributes?.value;
        const tagId = tag.id;
        const existingTag = tagsList.find((item) => item.key === tagValue);
        if (existingTag) {
          existingTag.value.push(tagId);
        } else {
          tagsList.push({
            key: tagValue,
            value: [tagId],
          });
        }
      }
    }
    if (appraisals?.length > 0) {
      let valuation = appraisals[0].attributes.valuation.data.attributes;
      fmv = valuation.value_fmv;
      rrv = valuation.value_rrv;
      lastReport = valuation.date_effective;
      mostRecentAppraisalId = appraisals[0].id;
    }
    date_acquired =
      attributes.date_acquired == null ? '' : attributes.date_acquired;
    description = attributes.description == null ? '' : attributes.description;
    location = attributes.location == null ? '' : attributes.location;
    condition = attributes.condition == null ? '' : attributes.condition;
    exhibitions = attributes.exhibitions == null ? '' : attributes.exhibitions;
    publications =
      attributes.publications == null ? '' : attributes.publications;
    if (subscription) {
      subscriptionId = subscription.id;
    }
    const attachments = attributes.attachments?.data;
    if (attachments) {
      attachments.forEach((item) => {
        let data = item.attributes?.media?.data;
        data.forEach((datum) => {
          let toPush = {
            name: datum.attributes?.name,
            url: datum.attributes?.url,
          };
          attachmentsArray.push(toPush);
        });
      });
    }
    const subscription_workspace_attributes =
      attributes.subscription_workspace?.data?.attributes;
    if (subscription_workspace_attributes) {
      organization_id =
        subscription_workspace_attributes.organization?.data?.id;
      organization_name =
        subscription_workspace_attributes.organization?.data?.attributes?.name;
    }
    const formattedData = {
      id: id,
      artwork_id: artwork_id,
      mostRecentAppraisalId: mostRecentAppraisalId,
      artist: artist,
      title: title,
      medium: medium,
      medium_category: medium_category,
      tags: tagsList,
      size_width: size_width,
      size_height: size_height,
      size_depth: size_depth,
      size_units: size_units,
      condition: condition,
      collection: collection,
      date_acquired: date_acquired,
      edition: edition,
      exhibitions: exhibitions,
      publications: publications,
      provenance: provenance,
      description: description,
      year: year,
      location: location,
      images: images,
      fmv: fmv,
      rrv: rrv,
      lastReport: lastReport,
      noteId: noteId,
      notes: notes,
      artwork_status: artwork_status,
      subscriptionId: subscriptionId,
      attachments: attachmentsArray,
      organization_id: organization_id,
      organization_name: organization_name,
    };
    return formattedData;
  };
  if (Array.isArray(data)) {
    return data.map(formatItem);
  }
  return formatItem(data);
};

export const formatPortfolioTitle = (title, item) => {
  return (
    <Link
      to={{ pathname: `/portfolio/${item.id}` }}
      state={{ itemData: item }}
      className={'portfolio-title'}
    >
      {title}
    </Link>
  );
};

export const formatReportTitle = (title, documentUrl) => {
  return (
    <a href={documentUrl} download className="link">
      {title}
    </a>
  );
};

export const formatAppraisalDate = (value, id) => {
  const toReturn = (
    <Link to={{ pathname: `/appraisals/${id}` }} className={'appraisal-date'}>
      {formatDate(value)}
    </Link>
  );
  return toReturn;
};

export const formatAppraisalApiResponse = (data) => {
  const formatAppraisal = (appraisal) => {
    const id = appraisal.id;
    const attributes = appraisal.attributes;
    let pastExposure = false;
    let title,
      artist,
      status,
      due_date = '';
    let valuation,
      item,
      internalNotes,
      requester = null;
    status = attributes.status == null ? '' : attributes.status;
    const item_attributes = attributes.item?.data?.attributes;
    if (item_attributes) {
      item = formatItemApiResponse(attributes.item.data);
      const artwork_attributes = item_attributes.artwork?.data?.attributes;
      if (artwork_attributes) {
        title =
          artwork_attributes.title == null ? '' : artwork_attributes.title;
        const artist_attributes = artwork_attributes.artist?.data?.attributes;
        if (artist_attributes) {
          artist =
            artist_attributes.full_name == null
              ? ''
              : artist_attributes.full_name;
        }
      }
      const subscription_workspace_attributes =
        item_attributes.subscription_workspace?.data?.attributes;
      if (subscription_workspace_attributes) {
        const workspace_org = subscription_workspace_attributes.organization;
        if (workspace_org) {
          requester = workspace_org.data?.attributes?.name;
        }
      }
    }
    const valuation_id = attributes.valuation?.data?.id;
    const valuation_attributes = attributes.valuation?.data?.attributes;
    if (valuation_attributes) {
      valuation = valuation_attributes;
      if (status === 'DELIVERED') {
        due_date =
          valuation.date_effective == null
            ? ''
            : formatDateAsYMD(new Date(valuation.date_effective));
      }
      let fmvComps = valuation.comparables_fmv?.data;
      let rrvComps = valuation.comparables_rrv?.data;
      valuation.comps_fmv = formatComps(fmvComps);
      valuation.comps_rrv = formatComps(rrvComps);
      let fmvNotes = valuation.note_fmv || '';
      let rrvNotes = valuation.note_rrv || '';
      valuation.note_fmv = fmvNotes;
      valuation.note_rrv = rrvNotes;
      valuation.id = valuation_id;
      pastExposure =
        valuation.past_exposure == null ? false : valuation.past_exposure;
      internalNotes =
        valuation.note_internal == null ? '' : valuation.note_internal;
    }

    const formattedData = {
      id: id,
      artist: artist,
      title: title,
      status: status,
      requester: requester,
      due_date: due_date,
      valuation: valuation,
      item: item,
      pastExposure: pastExposure,
      internalNotes: internalNotes,
    };
    return formattedData;
  };
  if (Array.isArray(data)) {
    return data.map(formatAppraisal);
  }
  return formatAppraisal(data);
};

export const formatPreviousAppraisals = (data) => {
  const formattedAppraisals = formatAppraisalApiResponse(data);
  let toReturn = [];
  formattedAppraisals.forEach((appraisal) => {
    const id = appraisal.id;
    if (appraisal.valuation) {
      let valuation_date,
        value_type,
        value,
        requester,
        approved_by = '-';
      let comps = [];
      let valuation = appraisal.valuation;

      for (let i = 0; i < 2; i++) {
        valuation_date = valuation.date_effective;
        value_type = i === 0 ? 'FMV' : 'RRV';
        value = i === 0 ? valuation.value_fmv : valuation.value_rrv;
        comps = i === 0 ? valuation.comparables_fmv : valuation.comparables_rrv;
        comps = formatComps(comps.data);
        requester = appraisal.requester;
        let comp1 = comps[0] ? comps[0].title : '';
        let comp2 = comps[1] ? comps[1].title : '';
        let comp3 = comps[2] ? comps[2].title : '';
        let formattedData = {
          id: id,
          valuation_date: valuation_date,
          value_type: value_type,
          value: value,
          comps: comps,
          requester: requester,
          approved_by: approved_by,
          comp1: comp1,
          comp2: comp2,
          comp3: comp3,
        };
        toReturn.push(formattedData);
      }
    }
  });
  return toReturn;
};

export const formatComps = (comps) => {
  const formatComp = (comp) => {
    const compId = comp.id;
    const comp_attributes = comp.attributes;
    const comp_note = comp_attributes.note;
    const comp_sale = comp_attributes.sale?.data?.id;
    const comp_title =
      comp_attributes.sale?.data?.attributes?.artwork?.data?.attributes?.title;
    const formattedData = {
      compId: compId,
      note: comp_note,
      sale: comp_sale,
      title: comp_title,
    };
    return formattedData;
  };
  if (Array.isArray(comps)) {
    return comps.map(formatComp);
  }
  return formatComp(comps);
};

export const formatCollection = (collection) => {
  return (
    <Link
      className={'collection-title'}
      to={{ pathname: '/portfolio' }}
      state={{
        filter: {
          collection: [
            {
              id: `collection-${collection}`,
              selector: '$eqi',
              query: collection,
            },
          ],
        },
      }}
    >
      {collection}
    </Link>
  );
};

export const formatItemNotes = (notes) => {
  return (
    <ul>
      {notes.map(({ attributes, id }) => (
        <li key={id}>{attributes.text}</li>
      ))}
    </ul>
  );
};

export const formatSize = (measurement, units) => {
  if (measurement === null) {
    return '-';
  }
  return `${measurement} ${units || '-'}`;
};

export const formatSortQuery = (orderBy, order) => {
  if (orderBy === 'date_acquired') {
    return `date_acquired:${order}`;
  } else if (['size_height', 'size_width', 'year'].includes(orderBy)) {
    return `artwork.${orderBy}:${order}`;
  } else if (orderBy === 'duration') {
    return `subscription.duration:${order}`;
  } else if (['lastReport', 'fmv', 'rrv'].includes(orderBy)) {
    return null; //TODO: figure out how to sort by valuation data
  } else {
    return null;
  }
};

export const formatGenericSortQuery = (orderBy, order) => {
  return `${orderBy}:${order}`;
};

export const formatSaleSortQuery = (orderBy, order) => {
  if (orderBy === 'sale_price') {
    return `amount:${order}`;
  } else if (orderBy === 'sale_date') {
    return `date:${order}`;
  } else if (orderBy === 'size_width' || orderBy === 'size_height') {
    return `artwork.${orderBy}:${order}`;
  } else {
    return null;
  }
};

export const formatAppraisalStatus = (status) => {
  const appraisalStatus = {
    DELIVERED: 'Delivered',
    NOT_STARTED: 'Not Started',
    IN_PROGRESS: 'In Progress',
    AWAITING_REVIEW: 'Awaiting Review',
    COMPLETED: 'Completed',
    CANCELED: 'Canceled',
  };
  return appraisalStatus[status];
};

export const formatAppraisalTitle = (title, row) => {
  return (
    <Link
      to={{ pathname: `/appraisals/${row.id}` }}
      state={{ appraisalData: row }}
      className={'appraisal-title'}
    >
      {title}
    </Link>
  );
};

export const formatUser = (user) => {
  if (user === undefined) {
    return '';
  }
  if (user.name_last && user.name_first) {
    return `${user.name_first} ${user.name_last}`;
  } else if (user.name_first) {
    return user.name_first;
  } else if (user.username) {
    return user.username;
  } else {
    return user.email;
  }
};

export const formatSaleSearchQuery = (searchQuery) => {
  return {
    $or: [
      {
        artwork: {
          title: {
            $containsi: searchQuery,
          },
        },
      },
      {
        artwork: {
          artist: {
            full_name: {
              $containsi: searchQuery,
            },
          },
        },
      },
      {
        artwork: {
          medium: {
            $containsi: searchQuery,
          },
        },
      },
    ],
  };
};

export const formatSaleApiResponse = (data) => {
  const formatSale = (sale) => {
    const id = sale.id;
    const attributes = sale.attributes;
    let title,
      artist,
      sale_price,
      list_price,
      est_lower,
      est_upper,
      sale_type,
      sale_date,
      size_height,
      size_depth,
      size_width,
      size_units,
      medium,
      year,
      condition,
      source_name,
      source_location,
      provenance = '';
    let image = null;
    sale_price = attributes.sale_price == null ? 0 : attributes.sale_price;
    list_price = attributes.list_price == null ? 0 : attributes.list_price;
    sale_type = attributes.type == null ? '' : attributes.type;
    sale_date = attributes.date == null ? '' : attributes.date;
    source_name = attributes.source_name == null ? '' : attributes.source_name;
    source_location =
      attributes.source_location == null ? '' : attributes.source_location;
    est_lower =
      attributes.auction_estimate_low == null
        ? 0
        : attributes.auction_estimate_low;
    est_upper =
      attributes.auction_estimate_high == null
        ? 0
        : attributes.auction_estimate_high;
    const artwork_attributes = attributes.artwork?.data?.attributes;
    if (artwork_attributes) {
      title = artwork_attributes.title == null ? '' : artwork_attributes.title;
      size_width =
        artwork_attributes.size_width == null
          ? ''
          : artwork_attributes.size_width;
      size_height =
        artwork_attributes.size_height == null
          ? ''
          : artwork_attributes.size_height;
      size_units =
        artwork_attributes.size_units == null
          ? ''
          : artwork_attributes.size_units;
      size_depth =
        artwork_attributes.size_depth == null
          ? ''
          : artwork_attributes.size_depth;
      medium =
        artwork_attributes.medium == null ? '' : artwork_attributes.medium;
      year = artwork_attributes.year == null ? '' : artwork_attributes.year;
      condition =
        artwork_attributes.condition == null
          ? ''
          : artwork_attributes.condition;
      provenance =
        artwork_attributes.provenance == null
          ? ''
          : artwork_attributes.provenance;
      const artist_attributes = artwork_attributes.artist?.data?.attributes;
      const images = artwork_attributes.images?.data;
      if (images?.length > 0) {
        image = images[0];
      }
      if (artist_attributes) {
        artist =
          artist_attributes.full_name === null ||
          artist_attributes.full_name === undefined
            ? ''
            : artist_attributes.full_name;
      }
    }
    const formattedData = {
      id: id,
      artist: artist,
      title: title,
      sale_type: sale_type,
      sale_price: sale_price,
      list_price: list_price,
      sale_date: sale_date,
      size_height: size_height,
      size_width: size_width,
      size_units: size_units,
      size_depth: size_depth,
      medium: medium,
      image: image,
      year: year,
      condition: condition,
      provenance: provenance,
      est_lower: est_lower,
      est_upper: est_upper,
      source_name: source_name,
      source_details: source_location,
    };
    return formattedData;
  };
  if (Array.isArray(data)) {
    return data.map(formatSale);
  }
  return formatSale(data);
};

export const formatArtworkSearchQuery = (searchQuery) => {
  return {
    $or: [
      {
        artist: {
          full_name: {
            $containsi: searchQuery,
          },
        },
      },
      {
        title: {
          $containsi: searchQuery,
        },
      },
    ],
  };
};

export const formatArtistSearchQuery = (searchQuery) => {
  return {
    $or: [
      {
        full_name: {
          $containsi: searchQuery,
        },
      },
      {
        biography: {
          $containsi: searchQuery,
        },
      },
      {
        nationality: {
          $containsi: searchQuery,
        },
      },
    ],
  };
};

export const formatArtworkFilterForQuery = (filter) => {
  let filterObj = {};
  for (const key in filter) {
    if (filter[key].length > 0) {
      if (key === 'updatedAt') {
        handleUpdatedAt(filter, filterObj);
      } else if (key === 'artist') {
        handleArtworkArtist(filter, filterObj);
      } else {
        handleArtworkField(filter, filterObj, key);
      }
    }
  }
  return filterObj;
};

const handleUpdatedAt = (filter, filterObj) => {
  filter['updatedAt'].forEach((item, index) => {
    const updatedAtQuery = {
      updatedAt: setDateRange(item.query),
    };
    const path = filter['updatedAt'].length === 1 ? [] : [index.toString()];
    addToFilterObj(filterObj, path, updatedAtQuery);
  });
};

const handleArtworkArtist = (filter, filterObj) => {
  filter['artist'].forEach((item, index) => {
    const artistName = item.query;
    const artistQuery = { artist: { full_name: { $containsi: artistName } } };
    const path = filter['artist'].length > 1 ? index.toString() : [];
    addToFilterObj(filterObj, path, artistQuery);
  });
};

const handleArtworkField = (filter, filterObj, key) => {
  const keys = ['status', 'title'];
  const combinedFilters = keys.reduce(
    (acc, key) => acc.concat(filter[key] || []),
    [],
  );
  if (combinedFilters.length === 1) {
    const item = combinedFilters[0];
    const path = [key, item.selector];
    addToFilterObj(filterObj, path, item.query);
  } else {
    filter[key].forEach((item, index) => {
      const path = ['$or', index.toString(), key, item.selector];
      addToFilterObj(filterObj, path, item.query);
    });
  }
};

export const formatArtistFilterForQuery = (filter) => {
  let filterObj = {};
  const handleArtistField = (filter, filterObj, key) => {
    const keys = [
      'full_name',
      'biography',
      'nationality',
      'date_of_birth',
      'date_of_death',
      'status',
    ];
    const combinedFilters = keys.reduce(
      (acc, key) => acc.concat(filter[key] || []),
      [],
    );
    if (combinedFilters.length === 1) {
      const item = combinedFilters[0];
      const path = [key, item.selector];
      addToFilterObj(filterObj, path, item.query);
    } else {
      filter[key].forEach((item, index) => {
        const path = ['$or', index.toString(), key, item.selector];
        addToFilterObj(filterObj, path, item.query);
      });
    }
  };

  for (const key in filter) {
    if (filter[key].length > 0) {
      handleArtistField(filter, filterObj, key);
    }
  }
  return filterObj;
};

export const formatArtistApiResponse = (data) => {
  if (data === undefined) return;
  const formatArtist = (artist) => {
    const attributes = artist.attributes;
    const formattedData = {
      id: artist.id,
      date_of_birth: attributes.date_of_birth || '',
      date_of_death: attributes.date_of_death || '',
      biography: attributes.biography || '',
      full_name: attributes.full_name || '',
      nationality: attributes.nationality || '',
      status: attributes.status || '',
    };
    return formattedData;
  };
  if (Array.isArray(data)) {
    return data.map(formatArtist);
  }
  return formatArtist(data);
};

export const formatArtworkApiResponse = (data) => {
  if (data === undefined) return;
  const formatArtwork = (artwork) => {
    const id = artwork.id;
    const attributes = artwork.attributes;
    let title,
      artist = '';
    let artistId = null;
    let updatedAt,
      status,
      year,
      medium,
      medium_category,
      size_height,
      size_width,
      size_depth,
      size_units = '';
    title = attributes.title;
    updatedAt = attributes.updatedAt;
    status = attributes.status === null ? '' : attributes.status;
    year = attributes.year === null ? '' : attributes.year;
    medium = attributes.medium;
    size_height = attributes.size_height === null ? '' : attributes.size_height;
    size_width = attributes.size_width === null ? '' : attributes.size_width;
    size_depth = attributes.size_depth === null ? '' : attributes.size_depth;
    size_units = attributes.size_units;
    medium_category =
      attributes.medium_category === undefined ||
      attributes.medium_category === null
        ? ''
        : attributes.medium_category;
    let artist_attributes = attributes.artist?.data?.attributes;
    if (artist_attributes) {
      artist = artist_attributes.full_name;
      artistId = attributes.artist.data?.id;
    }
    let images = attributes.images?.data;
    let imageIds = [];
    if (images) {
      for (const image of images) {
        if (images?.length > 0) {
          const imgId = image.id;
          imageIds.push(imgId);
        }
      }
    }
    const formattedData = {
      id: id,
      title: title,
      artist: artist,
      artistId: artistId,
      updatedAt: updatedAt,
      year: year,
      size_height: size_height,
      size_width: size_width,
      size_depth: size_depth,
      size_units: size_units,
      status: status,
      medium: medium,
      medium_category: medium_category,
      images: imageIds,
    };
    return formattedData;
  };
  if (Array.isArray(data)) {
    return data.map(formatArtwork);
  }
  return formatArtwork(data);
};

export const formatArtworkTitle = (title, row) => {
  return (
    <Link to={{ pathname: `edit-artwork/${row.id}` }} className="artwork-title">
      {title}
    </Link>
  );
};

export const formatArtistName = (name, row) => {
  return (
    <Link to={{ pathname: `edit-artist/${row.id}` }} className="artist-name">
      {name}
    </Link>
  );
};

export const formatDataStatus = (status) => {
  const dataStatus = {
    PUBLISHED: 'Published',
    AWAITING_REVIEW: 'Awaiting Review',
    DRAFT: 'Draft',
  };
  return dataStatus[status];
};

export const formatItemTags = (tagsList) => {
  let tagsString = '';
  for (let i = 0; i < tagsList.length; i++) {
    if (tagsString.length === 0) {
      tagsString = tagsList[i].key;
    } else {
      tagsString.concat(' | ', tagsList[i].key);
    }
  }
  return tagsString;
};

export const formatSubscriptionItems = (data) => {
  if (data === undefined) return;
  const formatSubscriptionItem = (subItem) => {
    let renewal_date = '';
    let item_id,
      id,
      duration = null;
    const stripe_data = subItem.stripe_data;
    const strapi_data = subItem.strapi_data;
    if (strapi_data) {
      id = strapi_data.id;
      duration = strapi_data.duration;
      const start_date = strapi_data.start_date;
      if (dateIsPast(start_date)) {
        if (stripe_data) {
          const phases = subItem.stripe_data.phases;
          if (phases) {
            const phase = phases[0];
            renewal_date = phase.end_date;
            if (renewal_date) {
              renewal_date = dayjs.unix(renewal_date);
            }
          }
        }
      } else {
        renewal_date = dayjs(start_date);
      }
    }
    const formattedData = {
      id: id,
      item_id: item_id,
      duration: duration,
      renewal_date: new Date(renewal_date).toISOString(),
      artist: '',
      title: '',
    };
    return formattedData;
  };
  if (Array.isArray(data)) {
    return data.map(formatSubscriptionItem);
  }
  return formatSubscriptionItem(data);
};

export const formatOrganizationApiResponse = (response) => {
  const formattedResponse = {
    id: response.data.id,
    name: response.data.attributes.name || '',
    mailing_address_street_primary:
      response.data.attributes.mailing_address_street_primary || '',
    mailing_address_street_secondary:
      response.data.attributes.mailing_address_street_secondary || '',
    mailing_address_city: response.data.attributes.mailing_address_city || '',
    mailing_address_state: response.data.attributes.mailing_address_state || '',
    mailing_address_country:
      response.data.attributes.mailing_address_country || '',
    does_business_as: response.data.attributes.does_business_as || '',
    phone_number: response.data.attributes.phone_number || '',
    email: response.data.attributes.email,
    stripe_customer_id: response.data.attributes.stripe_customer_id,
    workspaces: response.data.attributes.workspaces.data?.map((workspace) => ({
      id: workspace.id,
      name: workspace.attributes.name,
      items: workspace.attributes.items.data?.map((item) => ({
        id: item.id,
        title: item.attributes.artwork?.data?.attributes?.title,
        artist:
          item.attributes.artwork?.data?.attributes?.artist?.data?.attributes
            ?.full_name || '',
      })),
    })),
    avatar: response.data.attributes.avatar,
  };

  return formattedResponse;
};

export const formatAccessItemApiResponse = (data) => {
  if (data === undefined) return;
  if (!Array.isArray(data)) {
    data = [data];
  }
  let accessItems = [];
  data.forEach((item) => {
    accessItems.push({
      id: item.id,
      user_to: {
        id: item.attributes.user_to.data.id,
        name: formatUser(item.attributes.user_to),
        email: item.attributes.user_to.data.attributes.email,
      },
      role: item.attributes.access_role.data.id,
      organization_id: item.attributes.organization?.data?.id,
      organization_name: item.attributes.organization?.data?.attributes?.name,
      super: item.attributes.super,
    });
  });
  return accessItems;
};

export const groupAccessItemsByArtwork = (data) => {
  const groupedItems = {};
  data.forEach((entry) => {
    const accessId = entry.id;
    const attributes = entry.attributes;
    const itemId = attributes.item.data.id;
    const itemAttributes = attributes.item.data.attributes;
    const artworkAttributes = itemAttributes.artwork?.data?.attributes;
    const userTo = attributes.user_to.data.attributes;

    if (!groupedItems[itemId]) {
      groupedItems[itemId] = {
        id: itemId,
        title: artworkAttributes?.title || '',
        artist: artworkAttributes?.artist?.data?.attributes?.full_name || '',
        access_users: [],
      };
    }

    const accessUser = {
      access_item: {
        id: accessId,
        role: attributes.access_role.data.id,
        user_to: {
          id: attributes.user_to.data.id,
          email: userTo.email,
          username: userTo.username,
        },
      },
    };

    groupedItems[itemId].access_users.push(accessUser);
  });

  return Object.values(groupedItems);
};

export const formatSubscriptionFrequency = (freq) => {
  if (freq === 3) {
    return 'Quarterly';
  } else if (freq === 6) {
    return 'Every 6 Months';
  } else if (freq === 12) {
    return 'Yearly';
  } else if (freq === 0) {
    return 'None';
  } else {
    return '';
  }
};

export const formatPortfolioByClient = (items) => {
  let toReduce;
  if (Array.isArray(items)) {
    toReduce = items;
  } else {
    toReduce = items.data;
  }
  const reduced = toReduce.reduce((result, item) => {
    const organization =
      item.attributes.subscription_workspace?.data?.attributes?.organization
        ?.data?.attributes?.name ||
      formatUser(
        item.attributes.subscription_workspace?.data?.attributes?.user?.data
          ?.attributes,
      );
    if (!result[organization]) {
      result[organization] = [];
    }
    const formattedItem = formatItemApiResponse(item);
    result[organization].push(formattedItem);

    return result;
  }, {});
  let toReturn = [];
  Object.keys(reduced).forEach((org) => {
    let fmv_total = reduced[org].reduce((sum, item) => {
      return (item.fmv || 0) + sum;
    }, 0);
    let rrv_total = reduced[org].reduce((sum, item) => {
      return (item.rrv || 0) + sum;
    }, 0);
    let items = reduced[org].reduce((itemArray, item) => {
      if (itemArray.length > 4) {
        return itemArray;
      }
      if (item.image !== null && item.image !== undefined) {
        itemArray.push({ image: item.image, title: item.title });
      }

      return itemArray;
    }, []);
    let toPush = {
      organization: org,
      count_items: reduced[org].length,
      fmv_total: fmv_total,
      rrv_total: rrv_total,
      items: items,
    };
    toReturn.push(toPush);
  });
  return toReturn;
};

export const toTitleCase = (str) => {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const formatClientName = (client_name, row) => {
  return (
    <Link to={`/portfolio/clients/${row.type}/${row.id}`}>{client_name}</Link>
  );
};

export const formatClientPortfolioForVisualization = (data) => {
  return data.map((item) => ({
    name: item.client_name,
    type: item.type,
    id: item.id,
    value: item.value_fmv,
  }));
};

export const formatMediumCategory = (category) => {
  switch (category) {
    case 'MEDIUM_ARCHITECTURE_DESIGN':
      return 'Architecture & Design';
    case 'MEDIUM_DECORATIVE_ART':
      return 'Decorative Art';
    case 'MEDIUM_FILM_VIDEO_DIGITAL_MEDIA':
      return 'Film, Video & Digital Media';
    case 'MEDIUM_INSTALLATION_ART':
      return 'Installation Art';
    case 'MEDIUM_MIXED_MEDIA':
      return 'Mixed Media';
    case 'MEDIUM_NFT_BLOCKCHAIN_NEW_MEDIA':
      return 'NFT, Blockchain & New Media';
    case 'MEDIUM_PAINTING':
      return 'Painting';
    case 'MEDIUM_PHOTOGRAPHY':
      return 'Photography';
    case 'MEDIUM_PRINTS_GRAPHIC_ART':
      return 'Prints & Graphic Art';
    case 'MEDIUM_SCULPTURE':
      return 'Sculpture';
    case 'MEDIUM_WORKS_ON_PAPER':
      return 'Works on Paper';
    default:
      return '';
  }
};
