import {
  addToFilterObj,
  handleArtist,
  handleArtworkField,
} from './portfolio.helpers';

export const initializeFilterState = (cols) => {
  return cols.reduce((acc, column) => {
    acc[column.key] = [];
    return acc;
  }, {});
};

export const filterIsEmpty = (filter) => {
  if (filter === undefined || Object.values(filter).length === 0) {
    return true;
  }
  return !Object.values(filter).some((values) => {
    return values === undefined || values.length > 0;
  });
};

export const formatAppraisalFilter = (filter) => {
  let filterObj = {};
  for (const key in filter) {
    if (filter[key].length > 0) {
      if (key === 'status') {
        handleStatus(filter, filterObj);
      } else if (key === 'title') {
        handleTitle(filter, filterObj);
      } else if (key === 'artist') {
        handleSubscriptionCycleArtist(filter, filterObj);
      } else if (key === 'requester') {
        handleRequester(filter, filterObj);
      } else if (key === 'due_date') {
        handleDueDate(filter, filterObj);
      }
    }
  }
  return filterObj;
};

const handleStatus = (filter, filterObj) => {
  filter['status'].forEach((item, index) => {
    const path = ['$or', index.toString(), 'status', item.selector];
    addToFilterObj(filterObj, path, item.query);
  });
};

const handleTitle = (filter, filterObj) => {
  if (filter['title'].length === 1) {
    const item = filter['title'][0];
    let itemQuery = item.query;
    const path = ['item', 'artwork', 'title', item.selector];
    addToFilterObj(filterObj, path, itemQuery);
  } else {
    filter['title'].forEach((item, index) => {
      let itemQuery = item.query;
      const path = [
        'item',
        'artwork',
        '$or',
        index.toString(),
        'title',
        item.selector,
      ];
      addToFilterObj(filterObj, path, itemQuery);
    });
  }
};

const handleSubscriptionCycleArtist = (filter, filterObj) => {
  if (filter['artist'].length === 1) {
    const item = filter['artist'][0];
    let artistName = item.query;
    const artistQuery = {
      full_name: {
        $containsi: artistName,
      },
    };
    const path = ['item', 'artwork', 'artist'];
    addToFilterObj(filterObj, path, artistQuery);
  } else {
    filter['artist'].forEach((item, index) => {
      const artistName = item.query;
      const artistQuery = {
        full_name: {
          $containsi: artistName,
        },
      };
      const path = ['item', 'artwork', '$or', index.toString(), 'artist'];
      addToFilterObj(filterObj, path, artistQuery);
    });
  }
};

const handleRequester = (filter, filterObj) => {
  if (filter['requester'].length === 1) {
    const item = filter['requester'][0];
    const requesterQuery = {
      subscription_workspace: {
        organization: {
          name: {
            $containsi: item.query,
          },
        },
      },
    };
    addToFilterObj(filterObj, ['item'], requesterQuery);
  } else {
    filter['requester'].forEach((item, index) => {
      const requesterQuery = {
        subscription_workspace: {
          organization: {
            name: {
              $containsi: item.query,
            },
          },
        },
      };
      const path = ['item', '$or', index.toString()];
      addToFilterObj(filterObj, path, requesterQuery);
    });
  }
};

export const setDateRange = (date) => {
  const start = new Date(Array.isArray(date) ? date[0] : date);
  const end = new Date(Array.isArray(date) ? date[1] : date);
  start.setHours(0, 0, 0, 0);
  end.setHours(23, 59, 59, 999);
  return { $gte: start.toISOString(), $lte: end.toISOString() };
};

const handleDueDate = (filter, filterObj) => {
  filter['due_date'].forEach((item, index) => {
    const dueDateQuery = {
      valuation: {
        date_effective: setDateRange(item.query),
      },
    };
    const path = filter['due_date'].length === 1 ? [] : [index.toString()];
    addToFilterObj(filterObj, path, dueDateQuery);
  });
};

export const formatSaleFilter = (filter) => {
  let filterObj = {};
  for (const key in filter) {
    if (filter[key].length > 0) {
      if (
        [
          'sale_price',
          'list_price',
          'est_lower',
          'est_upper',
          'sale_date',
        ].includes(key)
      ) {
        handleSaleField(filter, filterObj, key);
      } else if (key === 'artist') {
        handleArtist(filter, filterObj, 'artist');
      } else if (['title', 'dims', 'medium']) {
        handleArtworkField(filter, filterObj, key);
      }
    }
  }
  return filterObj;
};

const handleSaleField = (filter, filterObj, key) => {
  const keys = [
    'sale_price',
    'list_price',
    'est_lower',
    'est_upper',
    'sale_date',
  ];
  const combinedFilters = keys.reduce(
    (acc, key) => acc.concat(filter[key] || []),
    [],
  );
  const numberFields = ['sale_price', 'list_price', 'est_lower', 'est_upper'];
  if (combinedFilters.length === 1) {
    const item = combinedFilters[0];
    let itemQuery = item.query;
    if (numberFields.includes(key)) {
      if (Array.isArray(itemQuery)) {
        itemQuery = [Number(itemQuery[0]), Number(itemQuery[1])];
      } else {
        itemQuery = Number(itemQuery);
      }
    }
    const path = [key, item.selector];
    addToFilterObj(filterObj, path, itemQuery);
  } else {
    filter[key].forEach((item, index) => {
      let itemQuery = item.query;
      if (numberFields.includes(key)) {
        if (Array.isArray(itemQuery)) {
          itemQuery = [Number(itemQuery[0]), Number(itemQuery[1])];
        } else {
          itemQuery = Number(itemQuery);
        }
      }
      const path = ['$or', index.toString(), key, item.selector];
      addToFilterObj(filterObj, path, itemQuery);
    });
  }
};

export const formatReportFilter = (filter) => {
  let filterObj = {};
  for (const key in filter) {
    if (filter[key].length > 0) {
      if (filter[key].length === 1) {
        const item = filter[key][0];
        let itemQuery = item.query;

        // Handle date ranges
        if (Array.isArray(itemQuery)) {
          const path = ['createdAt', '$between'];
          addToFilterObj(filterObj, path, [
            new Date(itemQuery[0]).toISOString(),
            new Date(itemQuery[1]).toISOString(),
          ]);
        } else {
          // Handle single value queries
          let path;
          if (key === 'file.name') {
            path = ['file', 'name', item.selector];
          } else {
            path = [key, item.selector];
          }
          addToFilterObj(filterObj, path, itemQuery);
        }
      } else {
        // Handle multiple filters with $or
        filter[key].forEach((item, index) => {
          let itemQuery = item.query;
          if (Array.isArray(itemQuery)) {
            const path = ['$or', index.toString(), 'createdAt', '$between'];
            addToFilterObj(filterObj, path, [
              new Date(itemQuery[0]).toISOString(),
              new Date(itemQuery[1]).toISOString(),
            ]);
          } else {
            let path;
            if (key === 'file.name') {
              path = ['$or', index.toString(), 'file', 'name', item.selector];
            } else {
              path = ['$or', index.toString(), key, item.selector];
            }
            addToFilterObj(filterObj, path, itemQuery);
          }
        });
      }
    }
  }
  return filterObj;
};
