import { getWorkspaces } from 'api/workspaces';
import { formatUser } from 'helpers/format.helpers';
import React, { createContext, useEffect, useState } from 'react';

import { useAuthContext } from './AuthContext';

export const WorkspaceContext = createContext({
  workspace: {
    id: undefined,
    name: '',
    organization_name: '',
    organization_id: null,
    organization_type: null,
  },
  setWorkspace: () => {},
  workspaces: [],
});

export const WorkspaceProvider = ({ children }) => {
  const { user, appraiserMode } = useAuthContext();
  const [workspace, setWorkspace] = useState('');
  const [workspaces, setWorkspaces] = useState([]);

  useEffect(() => {
    const loadWorkspaces = async () => {
      try {
        if (user && !appraiserMode) {
          const response = await getWorkspaces({
            params: {
              populate: ['organization', 'user'],
            },
          });
          if (response.data.length === 0) {
            setWorkspace(null);
          } else {
            const formattedWorkspaces = response.data?.map((item) => ({
              id: item.id,
              name: item.attributes.name,
              user: item.attributes.user?.data?.attributes || null,
              organization_id: item.attributes.organization.data?.id || null,
              organization_type:
                item.attributes.organization.data?.attributes?.type || 'OWNER',
              organization_name:
                item.attributes.organization.data?.attributes?.name ||
                `${formatUser(item.attributes.user?.data?.attributes)}'s Workspaces`,
            }));
            setWorkspaces(formattedWorkspaces);
            const savedWorkspace = sessionStorage.getItem('workspace');
            if (formattedWorkspaces.length > 0) {
              if (savedWorkspace) {
                setWorkspace(JSON.parse(savedWorkspace));
              } else {
                setWorkspace(formattedWorkspaces[0]);
              }
            }
          }
        }
      } catch (error) {
        console.error('Failed to load workspaces: ', error);
      }
    };

    loadWorkspaces();
  }, [user, appraiserMode]);

  useEffect(() => {
    if (appraiserMode) {
      setWorkspace(null);
    }
  }, [appraiserMode]);

  return (
    <WorkspaceContext.Provider value={{ workspace, setWorkspace, workspaces }}>
      {children}
    </WorkspaceContext.Provider>
  );
};
