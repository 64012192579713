import { createTheme } from '@mui/material/styles';

export const colors = [
  '#012221',
  '#99027D',
  '#220E99',
  '#490743',
  '#BC0439',
  '#30A8C4',
  '#C6DBCC',
  '#FFE6FF',
];
export const theme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: 'Plus Jakarta Sans',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          height: 'inherit',
          fontFamily: 'Plus Jakarta Sans',
        },
        input: {
          padding: 'inherit',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&.tertiary-body': {
            fontSize: 14,
            fontWeight: 300,
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&.tertiary-body': {
            fontSize: 14,
            fontWeight: 300,
            fontFamily: 'Plus Jakarta Sans',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          '.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input': {
            paddingRight: 0,
          },
        },
        select: {
          padding: 0,
          color: 'inherit',
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          borderSpacing: 'unset',
          borderCollapse: 'unset',
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: 'unset',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: 'unset',
        },
      },
    },
  },
});
