import apiClient from 'services/apiService';

export const getWorkspaces = (params) => {
  return apiClient.get('/workspaces', params).then((response) => response.data);
};

export const getWorkspaceById = (id, params) => {
  return apiClient
    .get(`/workspaces/${id}`, params)
    .then((response) => response.data);
};

export const putWorkspaceById = (id, data) => {
  return apiClient.put(`/workspaces/${id}`, data);
};

export const deleteWorkspaceById = (id) => {
  return apiClient.delete(`/workspaces/${id}`);
};

export const postWorkspace = (data) => {
  return apiClient.post('/workspaces', data);
};

export const postWorkspaceShare = (workspaceId, data) => {
  return apiClient.post(`/workspaces/${workspaceId}/share`, data);
};
