import apiClient from 'services/apiService';

export const getAccessItemsById = (id, params) => {
  return apiClient
    .get(`/access-items/item/${id}`, params)
    .then((response) => response.data);
};

export const getAccessRoles = () => {
  return apiClient.get('/access-roles').then((response) => response.data);
};

export const setAccess = (access_item_id, data) => {
  return apiClient.put(`/access-items/${access_item_id}`, data);
};

// Access Requests

export const getReceivedAccessRequests = async (params) => {
  const { data } = await apiClient.get(`/access-requests/received`, params);
  return data;
};

export const getSentAccessRequests = async (params) => {
  const { data } = await apiClient.get(`/access-requests/sent`, params);
  return data;
};

export const acceptAccessRequest = async (
  accessRequestId,
  workspaceId = null,
) => {
  let payload = {};
  if (workspaceId) {
    payload = {
      data: {
        workspace: workspaceId,
      },
    };
  }
  const { data } = await apiClient.post(
    `/access-requests/${accessRequestId}/accept`,
    payload,
  );
  return data;
};

export const rejectAccessRequest = async (accessRequestId) => {
  const { data } = await apiClient.put(
    `/access-requests/${accessRequestId}/reject`,
  );
  return data;
};

export const getAccessWorkspaces = (params) => {
  return apiClient
    .get(`/access-workspaces`, params)
    .then((response) => response.data);
};

export const getAccessWorkspacesByWorkspace = (workspaceId, params) => {
  return apiClient
    .get(`/access-workspaces/workspace/${workspaceId}`, params)
    .then((response) => response.data);
};

export const getAccessOrganizations = (params) => {
  return apiClient
    .get('/access-organizations', params)
    .then((response) => response.data);
};

export const getAccessOrganizationsByOrganization = (orgId, params) => {
  return apiClient
    .get(`/access-organizations/organization/${orgId}`, params)
    .then((response) => response.data);
};

export const getAccessItems = (params) => {
  return apiClient
    .get('/access-items', params)
    .then((response) => response.data);
};

export const putAccessOrganization = (id, data) => {
  return apiClient.put(`/access-organizations/${id}`, data);
};

export const putAccessWorkspace = (id, data) => {
  return apiClient.put(`/access-workspaces/${id}`, data);
};

export const getAccessItemsByWorkspace = (workspaceId, params) => {
  return apiClient
    .get(`/access-items/workspace/${workspaceId}`, params)
    .then((response) => response.data);
};

export const removeAccessOrganization = (accessOrgId, params) => {
  return apiClient.delete(`/access-organizations/${accessOrgId}`, params);
};

export const removeAccessWorkspace = (accessWorkspaceId, params) => {
  return apiClient.delete(`/access-workspaces/${accessWorkspaceId}`, params);
};

export const removeAccessItem = (accessItemId, params) => {
  return apiClient.delete(`/access-items/${accessItemId}`, params);
};
