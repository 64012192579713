import ChevronRight from 'assets/icons/ChevronRight.svg';
import { Link, useLocation } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

import './SidebarItem.scss';

function SidebarItem({ text, icon, linkDestination }) {
  let currentLocation = useLocation().pathname.substring(1);
  let linkToCompare = linkDestination.substring(1);
  if (currentLocation.includes('settings')) {
    let linkParts = linkDestination.split('/');
    linkToCompare = linkParts[2];
  }
  if (linkDestination.includes('document')) {
    linkToCompare = 'document';
  }

  const isActive =
    currentLocation === 'portfolio/access-requests'
      ? currentLocation === linkToCompare
      : currentLocation.includes(linkToCompare);

  return (
    <Link to={linkDestination}>
      <div
        data-testid="sidebar-item"
        className={`sidebar-item ${isActive ? 'selected' : ''} ${icon === null ? 'no-icon' : ''}`}
      >
        {icon !== null ? <ReactSVG src={icon} className="icon" /> : null}
        <span className="sidebar-text">{text}</span>
        <ReactSVG src={ChevronRight} className="hover-only icon" />
      </div>
    </Link>
  );
}

export default SidebarItem;
